import React from 'react';

import classes from './Subheader.module.scss';

const subheader = (props) => (
  <header className={classes.Subheader}>
    {props.children}
  </header>
)

export default subheader;