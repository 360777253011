import React from 'react';
import bookCover from '../../assets/images/book-cover-small.jpg';
import classes from './Logo.module.scss';

const logo = (props) => (
  <div className={classes.Logo}>
    <a href="/">
      <img 
        src={bookCover} 
        alt="TheCerberusAffair"/>
    </a>
  </div>
);

export default logo;