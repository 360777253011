import React from 'react';
import { Link } from 'react-router-dom';

import classes from './Footer.module.scss';
import Footer from '../../../assets/images/footer.png';

const footer = () => {
    return (
      <div className={classes.Footer}>
        <img src={Footer} alt='Double Helix Footer'/>
        <Link to='/privacy-policy'><p>Privacy Policy</p></Link>
      </div>
    )
  };

export default footer;