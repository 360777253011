import React from 'react';

import classes from './Fire.module.scss';
import Fire1 from '../../assets/images/fire1.png';
import Fire2 from '../../assets/images/fire2.png';
import Fire3 from '../../assets/images/fire3.png';
import Fire4 from '../../assets/images/fire4.png';

const fire = () => {
  return (
  <div className={classes.Fire}>
    <img src={Fire1} alt='fire' className={classes.Fire1}/>
    <img src={Fire2} alt='fire' className={classes.Fire2}/>
    <img src={Fire3} alt='fire' className={classes.Fire3}/>
    <img src={Fire4} alt='fire' className={classes.Fire4}/>
  </div>
)
}
export default fire;