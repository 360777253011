import React from 'react';

import './NavigationItems.scss'
import NavigationItem from './NavigationItem/NavigationItem';

const navigationItems = () => (
  <ul className='NavigationItems'>
    <NavigationItem link='/'>Home</NavigationItem>
    <NavigationItem link='buy-the-book'>Buy the Book</NavigationItem>
    <NavigationItem link='/author'>Author</NavigationItem>
  </ul>
);

export default navigationItems;