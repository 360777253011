import React, { useState, useEffect, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import classes from './App.module.scss';
import Layout from './components/Layout/Layout';
import DoubleHelix from './components/DoubleHelix/DoubleHelix';

const Home = React.lazy(() => import('./containers/Home/Home'));
const Author = React.lazy(() => import('./containers/Author/Author'));
const ReadTheStory = React.lazy(() => import('./containers/ReadTheStory/ReadTheStory'));
const PrivacyPolicy = React.lazy(() => import('./containers/PrivacyPolicy/PrivacyPolicy'));
const NotFound = React.lazy(() => import('./components/NotFound/NotFound'));

const App = () => {
  const [loading, setLoading] = useState(true) //set this back to true to get doublehelix

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 7000);
    return () => clearTimeout(timer);
  }, []);

    let page = (
      <div className={classes.FadeIn}>
        <Layout>
          <Switch>
            <Route path="/" exact render={() => <Home/>}/>
            <Route path="/author" render={() => <Author/>}/>
            <Route path="/buy-the-book" render={() => <ReadTheStory/>}/>
            <Route path="/privacy-policy" render={() => <PrivacyPolicy/>}/>
            <Route render={() => <NotFound/>}/>
          </Switch>
        </Layout>
      </div>
    )
 
    if (loading) {
      page = (
        <DoubleHelix/>
      )
    }
    
    return (
      <div className={classes.App}>

        <Suspense fallback={(<div></div>)}>
          {page}
        </Suspense>

      </div>
    );
  }

export default App;